/** @jsx jsx */
import React from 'react';
import { jsx, Heading, Box, Flex, Text } from 'theme-ui';
import { Link } from 'gatsby';

import Layout from '../components/layout';
import SEO from '../components/seo';

import GalleryImage from '../components/GalleryImage';

const paintings = [
  {
    title: 'Havis Amanda 1906',
    src: 'havis-amanda.jpg',
    description:
      'Havis Amanda is a nude female statue in Helsinki, Finland. It was sculpted by Ville Vallgren (1855-1940) in 1906 in Paris, but was not erected at its present location at the Market Square in Kaartinkaupunki until 1908.',
    original: 'Havis Amanda (2016), by Aleksi Leino',
    style:
      "<a href='https://en.wikipedia.org/wiki/Galatea_of_the_Spheres'>Galatea of the Spheres</a> (1952), by <a href='https://en.wikipedia.org/wiki/Salvador_Dal%C3%AD' target='_blank'>Salvador Dalí</a>",
  },
  {
    title: 'Bank of Finland 1883',
    src: 'bank-of-finland.jpg',
    description:
      'The Bank of Finland was established on 1 March in 1812 in the city of Turku by Alexander I of Russia. In 1819 it was relocated to Helsinki. The Bank created and regulated the Finnish Markka until Finland adopted the euro in 1999. It is the fourth oldest central bank in the world.',
    original:
      'The Bank of Finland with the statue of Johan Vilhelm Snellman, by Eixo',
    style:
      "<a href='https://en.wikipedia.org/wiki/Galatea_of_the_Spheres'>Galatea of the Spheres</a> (1952), by <a href='https://en.wikipedia.org/wiki/Salvador_Dal%C3%AD' target='_blank'>Salvador Dalí</a>",
  },
  {
    title: 'Esplanadi 1818',
    src: 'esplanadi.jpg',
    description:
      'In the heart of Helsinki, the capital of Finland, Esplanadi is an esplanade consisting of the Esplanadinpuisto park and the parallel one-way streets on its sides named Pohjoisesplanadi and Eteläesplanadi. The big green area between the two streets is very active in summer, where many Finns come to have a picnic. There are also numerous live music performances on a special outdoor stage in front of Cafe Kappeli. There is a statue of Johan Ludwig Runeberg by his son Walter Runeberg in the park',
    original:
      "<a href='https://commons.wikimedia.org/wiki/File:Estatua_de_Johan_Ludvig_Runeberg,_Esplanadi,_Helsinki,_Finlandia,_2012-08-14,_DD_01.JPG' target='blank'>Statue of Johan Ludvig Runeberg</a>, by <a href='https://commons.wikimedia.org/wiki/User:Poco_a_poco'>Diego Delso</a>",
    style:
      "A Sunday Afternoon on the Island of La Grande Jatte (1884), by <a href='https://en.wikipedia.org/wiki/Georges_Seurat' target='_blank'>Georges Seurat</a>",
  },
  {
    title: 'Helsinki Cathedral 1852',
    src: 'helsinki-cathedral.jpg',
    description:
      'A distinctive landmark in the Helsinki cityscape, with its tall, green dome surrounded by four smaller domes, the building is in the neoclassical style. It was designed by Carl Ludvig Engel as the climax of his Senate Square layout: it is surrounded by other, smaller buildings designed by him.',
    original: 'Helsinki Cathedral (2016), by Aleksi Leino',
    style:
      "<a href='https://en.wikipedia.org/wiki/Dio_%28band%29' target='_blank'>Dio</a>: <a href='https://en.wikipedia.org/wiki/Holy_Diver' target='_blank'>Holy diver</a> (1983) album cover",
  },
  {
    title: 'Helsinki Metro 1982',
    src: 'helsinki-metro.jpg',
    description:
      "The system's 17 stations, of which 9 are underground, make up a single forked line along a total length of 21.1 kilometres. The metro serves as the predominant rail link between the suburbs of East Helsinki and the city centre. The upcoming Länsimetro extension will continue the line through western Helsinki and the southern parts of the neighbouring city of Espoo.",
    original:
      "<a href='https://www.youtube.com/watch?v=1mhG8lkuE00' target='_blank'>Helsinki Metro, M300 train</a>, by <a href='https://www.youtube.com/channel/UCtD5g4PorrltoQ-4bRmwacA' target='_blank'>Jontsa73</a>",
    style:
      "Return of the three funny types, by <a href='https://en.wikipedia.org/wiki/Ces53' target='blank'>Ces53.</a>",
  },
  {
    title: 'Eteläranta 10 1952',
    src: 'etelaranta-10.jpg',
    description:
      'The Palace Hotel is an historic modernist hotel in Helsinki, opened in 1952, the year of the Helsinki Olympic Games. The building was designed by architects Viljo Revell and Keijo Petäjä, and was constructed during 1949–1952. In addition to the Palace Hotel and related restaurants, the building hosts also the main offices of the Confederation of Finnish Industry and Employers, whose member companies collectively contribute over 95 % of Finnish exports.',
    original:
      "<a href='https://commons.wikimedia.org/wiki/File:Palace_Hotel,_Helsinki,_East_view_20100825_1.jpg' target='_blank'>Palace Hotel, by DXR</a>",
    style: 'Scrooge McDuck diving into his vault, Anon.',
  },
  {
    title: 'Olympiastadion 1938',
    src: 'helsinki-olympic-stadium.jpg',
    description:
      'The Olympiastadion, located in the Töölö district about 2 kilometres from the centre of the Helsinki, is the largest stadium in the country, nowadays mainly used for hosting sports events and big concerts. The stadium is best known for being the centre of activities in the 1952 Summer Olympics. By the stadium you can find the iconic statue of Finnish middle and long distance runner, and multiple times gold medallist, Paavo Nurmi.',
    original: 'Helsinki Olympic Stadium (2016), by Aleksi Leino',
    style:
      "<a href='https://www.youtube.com/watch?v=YvkuGrGY8oc' target='_blank'>Goofy - The Olympic Champ</a> (1942), by Disney",
  },
  {
    title: 'Kiasma 1998',
    src: 'kiasma.jpg',
    description:
      'Kiasma is a contemporary art museum located on Mannerheimintie in Helsinki, Finland. Its name kiasma, Finnish for chiasma, alludes to the basic conceptual idea of its architect, Steven Holl.',
    original: 'Kiasma (2016), by Aleksi Leino',
    style:
      "Love By The Lake, by <a href='https://afremov.com/' target='_blank'>Leonid Afremov</a>",
  },
  {
    title: 'Helsinki Ice Hall 1966',
    src: 'nordis.jpg',
    description:
      'Helsinki Ice Hall, or "Nordis", located by Nordeskiöldinkatu street is the home arena for HIFK ice hockey team, founded in 1897.',
    original: 'Nordeskiöldinkatu (2016), by Aleksi Leino',
    style:
      "Pond Hockey on Farm, by <a href='https://richardbrodeur.wordpress.com/' target='_blank'>Richard Brodeur</a>",
  },
  {
    title: 'Otso & Kontio 1986',
    src: 'otso-kontio.jpg',
    description:
      'Otso and Kontio are Finnish state owned ice breaker built by Wärtsilä Helsinki shipyard in 1986 and 1987.',
    original:
      "<a href='https://commons.wikimedia.org/wiki/File:Finnish_Icebreakers_Kontio%26Otso_2008.jpg' target='_blank'>Finnish Icebreakers Kontio &amp; Otso</a>, by <a href='https://commons.wikimedia.org/wiki/User:BKfi' target='_blank'>Matti Paavonen</a>",
    style:
      "Luck of the North (1949), by <a href='https://en.wikipedia.org/wiki/Carl_Barks' target='_blank'>Carl Barks</a>",
  },
  {
    title: 'Postitalo 1938',
    src: 'postitalo.jpg',
    description:
      'Postitalo was the headquartes for Posti Group\'s predecessor <em>"Posti- ja telelaitos"</em>. This functionalists style building was designed by Kaarlo Borg, Jorma Järvi and Erik Lindroos. From time to time Posti Group is known to have almost as reliable deliveries as Postman Pat and his black and white cat.',
    original: 'Postitalo (2016), by Aleksi Leino',
    style:
      "Postman Pat and His Black and White Cat (1981 - 1996), by <a href='https://en.wikipedia.org/wiki/Ivor_Wood' target='_blank'>Woodland Animations</a>",
  },
  {
    title: 'Helsinki Railway Station 1919',
    src: 'helsinki-railway-station.jpg',
    description:
      "Helsinki Central Railway Station was designed by Eliel Saarinen. It was chosen as one of the world's most beautiful railway stations by BBC in 2013. The station is mostly clad in Finnish granite, and its distinguishing features are its clock tower and the two pairs of statues holding the spherical lamps, lit at night-time, on either side of the main entrance. Supercell is a Finnish game design studio with revenues over two billion dollars. It's mainly known for its hit game <em>Clash of Clans</em>.",
    original: 'Helsinki Railway Station (2016), by Aleksi Leino',
    style:
      "Golem Character from Clash of Clans by <a href='https://en.wikipedia.org/wiki/Supercell_%28video_game_company%29' target='_blank'>Supercell</a>",
  },
  {
    title: 'Stockmann 1930',
    src: 'stockmann.jpg',
    description:
      'Stockmann department store was established by Georg Franz Stockmann, a German merchant from Lübeck, in 1862. Its iconic building was designed by Sigurd Frosterius and it was built in 1930 Especially the clock at the main entrance, colloquially "Stockan kello" ("Stocky\'s clock"), has become a symbol of Helsinkian city culture as a popular meeting place.',
    original: 'Stockmann (2016), by Aleksi Leino',
    style:
      "<a href='https://en.wikipedia.org/wiki/The_Persistence_of_Memory'>The Persistence of Memory</a> (1931), by <a href='https://en.wikipedia.org/wiki/Salvador_Dal%C3%AD' target='_blank'>Salvador Dalí</a>",
  },
  {
    title: 'Töölö bay',
    src: 'toolo-bay.jpg',
    description:
      "Nature in the middle of the city. Between Helsinki Central Railway station and Finlandia Hall lies the Töölönlahti Bay. It's a perfect place for taking a relaxing walk. Besides the Finlandia Hall, you will spot at least villas from early 1900's, Helsinki Opera House, lot's of people exercising, and probably some swans.",
    original: 'Töölö Bay (2016), by Aleksi Leino',
    style:
      "<a href='https://en.wikipedia.org/wiki/The_Great_Wave_off_Kanagawa'>The Great Wave off Kanagawa</a> (1829 - 1832), by <a href='https://en.wikipedia.org/wiki/Hokusai'>Katsushika Hokusai</a>",
  },
  {
    title: 'Vltava Restaurant',
    src: 'vltava-restaurant-helsinki.jpg',
    description:
      "Vltava is a Czech restaurant in Eliel Square in the heart of Helsinki. The four stories of our Jugend style building create an environment full of atmosphere that is ideal for meetings, social gatherings and parties. In the typically Czech way, Vltava loves hearty meals and good beer. Vltava has, among other things, the city's widest range of Czech beers.",
    original: 'Vltava (2016), by Aleksi Leino',
    style: 'Vltava River, Prague, Anon.',
  },
];

const paragraphStyle = {
  maxWidth: '600px',
};

const SecondPage = () => (
  <Layout>
    <SEO title="Gala | Helsinki Neural Art Gallery" />
    <Heading
      sx={{
        fontSize: 7,
        marginBottom: [null, null, 4],
      }}
    >
      Gala
    </Heading>
    A Helsinki Neural Art Gallery.<br/> Surrealistic art from Helsinki based on <em>Neural Style Transfer</em> method descibed in Li et al. paper {' '}
          <a
            href="https://arxiv.org/abs/1601.04589"
            target="_blank"
            rel="noopener noreferrer"
          >
            Combining Markov Random Fields and Convolutional Neural Networks for
            Image Synthesis
          </a>.<br/><br/>
          <a href="#about">Read more...</a> <br/>
    <Flex
      sx={{
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        // maxWidth: '75ch',
        textAlign: 'justify',
      }}
    >
      {paintings.map(p => (
        <Box
          sx={{
            width: '100%',
            marginBottom: '96px',
            marginTop: '48px',
            '& a': {
              textDecoration: 'none',
              textDecoration: 'underline',

              color: '#222',
              fontWeight: 'semibold',
            },
          }}
        >
          <Box
            sx={{
              width: '100%',
            }}
          >
            <GalleryImage
              src={p.src}
              style={{
                marginBottom: '24px',
              }}
            />
          </Box>
          <Box
            sx={{
              BoxDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              maxWidth: '75ch',
              textAlign: 'justify',
            }}
          >
            <Text
              sx={{
                fontSize: 5,
                fontWeight: 'bold',
                marginBottom: 2,
              }}
            >
              {p.title}
            </Text>
            <Text
              mb={3}
              sx={{
                fontWeight: 'light',
              }}
            >
              {p.description}
            </Text>
            <span>
              <strong>Original: </strong>
            </span>
            <span dangerouslySetInnerHTML={{ __html: p.original }}></span>
            <br />
            <span>
              <strong>Style: </strong>
            </span>
            <span dangerouslySetInnerHTML={{ __html: p.style }}></span>
          </Box>
        </Box>
      ))}

      <Box
        sx={{
          maxWidth: '75ch',
          textAlign: 'justify',
        }}
      >
        <Heading variant="h1">About</Heading>
        <Text>
          In <strong>26.8.2015</strong> Leon Gatys, Alexander Ecker and Matthias Bethge published
          a paper titled{' '}
          <a
            href="https://arxiv.org/abs/1508.06576"
            target="_blank"
            rel="noopener noreferrer"
          >
            A Neural Algorithm of Artistic Style
          </a>{' '}
          where they described how to use higher level abstractions from deep
          neural nets to match style from one image to another image's content.
          Previously attempts for style transfer had mainly used pixel level
          reprentations to achieve this goal.
        </Text>
        <br />
        <Text>
          The pretrained{' '}
          <a
            href="https://arxiv.org/abs/1409.1556"
            target="_blank"
            rel="noopener noreferrer"
          >
            VGG-19 network
          </a>{' '}
          used in their method can effectively capture higher level abstractions
          and transfer style from artwork into a structure of a selected image.
        </Text>
        <br />
        <Text>
          In <strong>18.1.2016</strong> Chuan Li & Michael Wand published a paper titled{' '}
          <a
            href="https://arxiv.org/abs/1601.04589"
            target="_blank"
            rel="noopener noreferrer"
          >
            Combining Markov Random Fields and Convolutional Neural Networks for
            Image Synthesis
          </a>{' '}
          improved this approach to better adapt local features during the style
          transfer. Key idea of their research is to use local image patches and
          Markov random fields to match local features from style image into
          content image.
        </Text>
        <br />
        <Text mb={4}>
          The local matching used in their method helps to produce more natural
          images with less noise compared to Gatys et al. model. However,
          there's a drawback: When good matches are not found this method tends
          to produce very hallucinogenic images with very little context to
          original content image.
        </Text>
        <Heading variant="h3">Implementation</Heading>
        <Text mb={4}>
          All images presented were generated using Li's methdod and{' '}
          <a
            href="https://github.com/chuanli11/CNNMRF"
            target="_blank"
            rel="noopener noreferrer"
          >
            code
          </a>
          , an AWS Nvidia Grid K520 GPU and Andrei Karpathy's Deep Learning
          image.
        </Text>
        <Heading variant="h3" id="about">Notes</Heading>
        <ui>
          <li>
            Nvidia Grid K520 GPU (4GB RAM) didn't seem to like images bigger
            than 800×500 px. Try algorithm with bigger images using memory mode
            instead of speed mode.
          </li>
          <li>
            Try images with more square aspect ratio. Preliminary tests with
            this algorithm seemed to produce more crisp images when using more
            square aspect ratios.
          </li>
        </ui>
      </Box>
    </Flex>
  </Layout>
);

export default SecondPage;
